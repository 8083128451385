<template>
  <div id="avatar">
    <div id="widget">
      <div class="colorwheel-wrapper">
        <div class="colorwheel-selector">
          <svg viewBox="-1 -1 2 2">
            <g v-for="(segment, index) in segments" :key="segment.fill" :class="{ selected: segment.selected, segment }"
              @click="setSegment(index)">
              <path :d="segment.d" :fill="'#' + segment.fill"></path>
            </g>
            <circle cx="0" cy="0" r=".5" stroke="black" stroke-width=".001" fill="white"></circle>
          </svg>
        </div>
        <!-- <input
          type="text"
          pattern="[a-f0-9]{6}"
          readonly="readonly"
          title="Value"
          class="colorwheel-value"
        /> -->
      </div>
    </div>
    <img alt="oakabilly" class="dynamo-border" src="@/assets/profile.jpg" title="Flavio N Quercia"
      :style="{ borderColor: '#' + color }" />
    <span :style="{ backgroundColor: '#' + color }"></span>
  </div>
</template>
<script>
export default {
  props: {
    color: String
  },
  data() {
    return {
      segments: [
        { d: "M 0.8738476356834468 -0.215384097858802 A 0.9 0.9 0 0 1 0.8738476356834468 0.215384097858802 L 0 0", fill: "C09BD8", selected: false },
        { d: "M 0.8738476356834468 0.215384097858802 A 0.9 0.9 0 0 1 0.673659673353991 0.5968103924167157 L 0 0", fill: "ff6600", selected: false },
        { d: "M 0.673659673353991 0.5968103924167157 A 0.9 0.9 0 0 1 0.319144398338282 0.8415146184168734 L 0 0", fill: "9AD2CB", selected: false },
        { d: "M 0.319144398338282 0.8415146184168734 A 0.9 0.9 0 0 1 -0.108483012229791 0.8934379866882486 L 0 0", fill: "B2967D", selected: false },
        { d: "M -0.108483012229791 0.8934379866882486 A 0.9 0.9 0 0 1 -0.5112582720580402 0.7406854793042909 L 0 0", fill: "38369A", selected: false },
        { d: "M -0.5112582720580402 0.7406854793042909 A 0.9 0.9 0 0 1 -0.7969104230878891 0.41825085483939145 L 0 0", fill: "416165", selected: false },
        { d: "M -0.7969104230878891 0.41825085483939145 A 0.9 0.9 0 0 1 -0.9 -6.891423658068509e-16 L 0 0", fill: "B4654A", selected: false },
        { d: "M -0.9 -6.891423658068509e-16 A 0.9 0.9 0 0 1 -0.7969104230878887 -0.41825085483939195 L 0 0", fill: "D0D38F", selected: false },
        { d: "M -0.7969104230878887 -0.41825085483939195 A 0.9 0.9 0 0 1 -0.5112582720580403 -0.7406854793042907 L 0 0", fill: "393E41", selected: false },
        { d: "M -0.5112582720580403 -0.7406854793042907 A 0.9 0.9 0 0 1 -0.10848301222979043 -0.8934379866882487 L 0 0", fill: "8377D1", selected: false },
        { d: "M -0.10848301222979043 -0.8934379866882487 A 0.9 0.9 0 0 1 0.3191443983382828 -0.841514618416873 L 0 0", fill: "26F0F1", selected: false },
        { d: "M 0.3191443983382828 -0.841514618416873 A 0.9 0.9 0 0 1 0.6736596733539912 -0.5968103924167154 L 0 0", fill: "4B5043", selected: false },
        { d: "M 0.6736596733539912 -0.5968103924167154 A 0.9 0.9 0 0 1 0.8738476356834468 -0.21538409785880203 L 0 0", fill: "561F37", selected: false }
      ],
    }
  },
  created() {
    const index = Math.floor(Math.random() * this.segments.length);
    this.setSegment(index)
  },
  methods: {
    setSegment(index) {
      this.$parent.color = this.segments[index].fill

      this.segments.forEach((segment) => {
        segment.selected = false;
      })

      this.segments[index].selected = true;
    }
  }
};
</script>