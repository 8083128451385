
<script>
import Avatar from '@/components/Avatar'
import Blog from '@/components/Blog'
import Projects from '@/components/Projects'
import { faCode } from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    Avatar,
    Blog,
    Projects
  },
  data() {
    return {
      color: '4B4B4B'
    }
  }
}
</script>

<template>
  <div>
    <div class="container-fluid">
      <div class="row py-5">

        <!-- /*** ME COL ***/ -->
        <div id="me" class="col col-12 col-lg-4 offset-lg-1 mb-5 mb-md-0">

          <div id="intro" class="row text-center mb-5 pb-5">

            <!-- Avatar -->
            <div class="col col-12 mb-5">
              <avatar :color="color" />
            </div>

            <!-- Title -->
            <div class="col col-12 mb-5">
              <h1 :style="{ color: '#' + color }">Flavio N Quercia</h1>
              <p class="h2">Sviluppatore Web</p>
            </div>

            <!-- Contact -->
            <div id="contact" class="col col-12">
              <a class="mx-1" href="mailto:oakabilly@gmail.com" target="_blank" title="Email"
                :style="{ backgroundColor: '#' + color }">Email</a>
              <a class="mx-1" href="https://www.linkedin.com/in/oakabilly/" target="_blank" title="LinkedIn"
                :style="{ backgroundColor: '#' + color }">LinkedIn</a>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col col-12 col-sm-2 d-flex align-items-center justify-content-center mb-3">
              <font-awesome-icon :icon="['fas', 'code']" :style="{ color: '#' + color, fontSize: '32px' }" />
            </div>
            <div class="col col-12 col-sm-8 offset-sm-1 text-center text-sm-left">
              <h2 class="h4 mb-3">Sto lavorando con</h2>
              <p>Laravel, Laravel Nova<br />
                VueJS, Nuxt</p>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col col-12 col-sm-2 d-flex align-items-center justify-content-center mb-3">
              <font-awesome-icon :icon="['fas', 'flask']" :style="{ color: '#' + color, fontSize: '32px' }" />
            </div>
            <div class="col col-12 col-sm-8 offset-sm-1 text-center text-sm-left">
              <h2 class="h4">Sto sperimentando con</h2>
              <p>Flutter, NativeScript<br />CMS Headless, Firebase</p>
            </div>
          </div>

        </div><!-- </COL> -->

        <!-- /*** LOG COL ***/ -->
        <div id="log" class="col col-12 col-lg-5 offset-lg-1">
          <!-- Projects -->
          <div id="projects">
            <h2 :style="{ color: '#' + color }">Progetti</h2>
            <projects :color="color" />
          </div>
          <!-- Blog -->
          <div id="blog">
            <h2 :style="{ color: '#' + color }">Blog</h2>
            <blog :color="color" />
          </div>

        </div><!-- </COL> -->

      </div><!-- </ROW> -->

    </div>



    <img alt="" id="bug" src="@/assets/bug.gif" />

  </div>
</template>
