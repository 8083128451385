<script>
import db from "@/firebase/config.js"
import { collection, getDocs } from "firebase/firestore";

export default {
  props: {
    color: String,
  },
  data() {
    return {
      loading: true,
      items: [],
      posts_per_page: 5
    };
  },
  async created() {
    const querySnapshot = await getDocs(collection(db, "projects"));
    querySnapshot.forEach((doc) => {
      const project = doc.data()
      if (!project.date) {
        project['date'] = this.$moment()
      } else {
        project['date'] = this.$moment(project.date.toDate())
      }
      this.items.push(project)
    });
    this.loading = false
  },
  computed: {
    posts() {
      return this.items.sort((a, b) => {
        return b.date - a.date
      }).slice(0, this.posts_per_page)
    }
  },
  filters: {
    toPrettyUrl(url) {
      return url.replace('https://', '')
    }
  },
  methods: {
    loadmore() {
      let posts_per_page = this.posts_per_page + 5;
      if (posts_per_page > this.items.length) {
        posts_per_page = this.items.length
      }

      this.posts_per_page = posts_per_page;
    }
  }
};
</script>

<template>
  <div>
    <div v-if="loading">
      <img class="loading" alt="Loading..." src="@/assets/loader.gif" />
    </div>
    <div v-else>
      <ul v-if="posts.length > 0">
        <li v-for="post in posts" :key="post.title">
          <div class="header">
            <h3 class="h5">
              {{ post.title }}
            </h3>
            <span class="date">
              {{ post.date | moment('MM/YYYY') }}
            </span>
          </div>
          <div class="meta">
            <span class="tech">
              <font-awesome-icon :icon="['fas', 'desktop']" />
              {{ post.meta }}
            </span>
            <span class="link" v-if="post.link">
              <font-awesome-icon :icon="['fas', 'external-link-alt']" />
              <a :href="post.link" target="_blank" :title="post.title">{{
                post.link | toPrettyUrl
              }}</a>
            </span>
          </div>
        </li>
      </ul>
      <ul v-else>
        <li>No projects found</li>
      </ul>
      <div class="pagination mt-5" v-if="posts.length > 0">
        <span>[ {{ posts_per_page }} / {{ items.length }} ]</span>
        <a @click="loadmore" v-if="items.length > posts_per_page">Load more</a>
      </div>
    </div>
  </div>
</template>