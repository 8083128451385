<script>
import db from "@/firebase/config.js"
import { collection, getDocs } from "firebase/firestore";

export default {
  props: {
    color: String,
  },
  data() {
    return {
      loading: true,
      items: [],
    };
  },
  async created() {
    const querySnapshot = await getDocs(collection(db, "blog"));
    querySnapshot.forEach((doc) => {
      const item = doc.data()
      item['date'] = this.$moment(item.date.toDate())
      this.items.push(item)
    });
    this.loading = false
  },
  computed: {
    posts() {
      return this.items.sort((a, b) => {
        return b.date - a.date
      }).slice(0, this.posts_per_page)
    }
  },
  filters: {
    toPrettyUrl(url) {
      return url.replace("https://", "");
    },
  },
};
</script>

<template>
  <ul v-if="posts.length > 0">
    <li v-for="post in posts" :key="post.id">
      <div class="header">
        <h3 class="h5">
          <a class="external" :href="post.link" target="_blank" :title="post.title">
            {{ post.title }}
          </a>
        </h3>
      </div>
      <p>
        {{ post.description }}
      </p>
      <div class="meta">
        <span class="pub_date">
          <font-awesome-icon :icon="['fas', 'clock']" />
          {{ post.date | moment("DD/MM/YYYY") }}
        </span>
        <span class="link">
          <font-awesome-icon :icon="['fas', 'external-link-alt']" />
          <a :href="post.link" target="_blank" :title="post.title">
            {{ post.link | toPrettyUrl }}
          </a>
        </span>
      </div>
    </li>
  </ul>
  <ul v-else>
    <img class="loading" alt="Loading..." src="@/assets/loader.gif" />
  </ul>
</template>
