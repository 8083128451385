import Vue from 'vue'
import App from './App.vue'
/* Firebase Cloud Functions import */
/* Bootstrap import */
import BootstrapVue from "bootstrap-vue"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
Vue.use(BootstrapVue)
/* Fortawesome import */
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCode,faClock,faFlask,faDesktop,faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faCode,faClock,faFlask,faDesktop,faExternalLinkAlt)
Vue.component('font-awesome-icon', FontAwesomeIcon)
/* Moment import */ 
import moment from 'vue-moment'
Vue.use(moment);
/* My imports */
import "@/assets/style.css"
/* Firebase import */
import "@/firebase/config.js"

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
