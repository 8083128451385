import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC6eEpQfRXPQpm-Q6O6J9tO_Mo0lJAvaoU",
    authDomain: "oakabilly-vue2.firebaseapp.com",
    databaseURL: "https://oakabilly-vue2-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "oakabilly-vue2",
    storageBucket: "oakabilly-vue2.appspot.com",
    messagingSenderId: "818984299423",
    appId: "1:818984299423:web:32ecacaadaea17e1fa2baa",
    measurementId: "G-V7W1HXM23P"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app)

export default db